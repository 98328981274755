export const FEATURES: FeatureProps[] = [
  {
    title: 'A portal for a wide range of scholarship opportunities',
    desc: 'We recognize the need for a centralized source where students can easily search and apply for scholarship opportunities. Scholaris - a scholarship pooling application, will help bridge students and available opportunities, resolving challenges experienced by students when searching for scholarships.',
    image: '/images/img_feat-student-1.png',
  },
  {
    title:
      'Find your scholarships of interest with search filters such as benefits, courses, and schools.',
    desc: 'Easily search for scholarships according to your preference: benefits, chosen courses, and schools. These filters provide a more refined set of scholarship options for students. Never miss an application due date by filtering scholarships by their application dates!',
    image: '/images/img_feat-student-2.png',
  },
  {
    title:
      'Learn about scholarships offered by different Scholarship Granting Organizations (SGOs)',
    desc: 'Students may view profiles of different SGOs - which may vary between scholarship providers and schools, showcasing their background and scholarship offerings. Access to SGO profiles will assist students in finding and applying for scholarships that align with their interests and qualifications.',
    image: '/images/img_feat-student-3.png',
  },
]

export const STUDENT_WELCOME_SUBHEADER: string = `Are you looking for the right scholarship?`
export const STUDENT_WELCOME_THIRD_LEVEL_HEADING: string = `Scholaris is a platform where students can discover a wide range of scholarship opportunities. Our search engine can help you find scholarships that cater to your specific needs, whether you’re a high school or college student, an athlete, or a musician.`
