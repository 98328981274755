import React from 'react'
import './TextLoading.css'

const TextLoading: React.FC = () => {
  return (
    <div className="text__animated">
      <div className="text__animated-background btn-divide-left"></div>
    </div>
  )
}

export default TextLoading
